export let environment = {
  production: true,
  appVersion: '5.6',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  // TFS
  apiUrl: "https://luminsmithapi.azurewebsites.net/",
  googleRecaptchaSiteKey: "6LfbwvkkAAAAAG5YHFg74Y9jgdXzRiUcubKXiK4w",
  firebase: {
    apiKey: "AIzaSyDkVpPglD3sOlwew4T6chh8M2Zpf10EFwY",
    authDomain: "g-4bedf.firebaseapp.com",
    databaseURL: "https://g-4bedf.firebaseio.com",
    projectId: "g-4bedf",
    storageBucket: "g-4bedf.appspot.com",
    messagingSenderId: "199933238242",
    appId: "1:199933238242:web:513d2eb532edda722fb7bb",
    measurementId: "G-QWMM7CKGPD"
  }
};
try {
  let systemConfig = JSON.parse(localStorage.getItem("systemConfiguration"));
  environment.googleRecaptchaSiteKey = systemConfig.find(con => con.key == 'CaptchaKey').value
} catch {}
