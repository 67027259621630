import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SplashScreenService } from './splash-screen.service';
import { SystemConfigurationService } from 'src/app/modules/system-configuration/services/system-configuration.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, AfterViewInit {
  @ViewChild('splashScreen') splashScreen: ElementRef;
  private splashSub: Subscription;

  constructor(
    public splashScreenService: SplashScreenService,
    public systemConfigurationService: SystemConfigurationService
  ) {}

  systemConfig = JSON.parse(localStorage.getItem('systemConfiguration'));

  ngOnInit(): void {
    // Subscribe to the splash screen visibility changes
    this.splashSub = this.splashScreenService.iSLoginSplash.subscribe((isVisible) => {
      if (isVisible && this.splashScreen) {
        if (window.location.href.includes('home')) {
          this.splashScreen.nativeElement.classList.add('bg-light');
        } else {
          this.splashScreen.nativeElement.classList.remove('bg-light');
        }
      }
    });
  }

  // Initialize the splash screen element
  ngAfterViewInit(): void {
    this.splashScreenService.init(this.splashScreen); // Initialize the service with the element
  }

  ngOnDestroy(): void {
    if (this.splashSub) {
      this.splashSub.unsubscribe(); // Clean up the subscription
    }
  }
}
