import { Injectable, Inject, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  ITableState,
  TableResponseModel,
  TableService,
} from "../../../_metronic/shared/crud-table";
import { environment } from "../../../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { baseFilter } from "src/app/_fake/fake-helpers/http-extenstions";
import { MyResponseList, MyResponseSingle } from "../../shared/models/response";
import { RestParams } from "../../shared/models/params";
import { SystemConfiguration } from "../models/system-configuration.model";

@Injectable({
  providedIn: "root",
})
export class SystemConfigurationService
  extends TableService<SystemConfiguration>
  implements OnDestroy
{
  private _params: RestParams = new RestParams(["Key", "Value"]);
  API_URL = `${environment.apiUrl}SystemConfiguration/`;
  isDataLoaded = false;
  defaultImg = './assets/img/default.jpg';

  constructor(@Inject(HttpClient) http) {
    super(http);
  }
  private readonly _systemConfigurations$ = new BehaviorSubject<
    SystemConfiguration[]
  >([]);
  _systemConfigurations: SystemConfiguration[] = [];
  get systemConfigurations(): SystemConfiguration[] {
    return this._systemConfigurations$.value;
  }
  set systemConfigurations(nextState: SystemConfiguration[]) {
    this._systemConfigurations$.next(nextState);
  }
  private _total$ = new BehaviorSubject<number>(0);
  get params(): RestParams {
    return this._params;
  }
  set params(params) {
    this._params = params;
  }

  get total$() {
    return this._total$.asObservable();
  }

  // READ
  find(
    tableState: ITableState
  ): Observable<TableResponseModel<SystemConfiguration>> {
    return this.http
      .get<MyResponseList>(
        this.API_URL +
          "GetAll?" +
          Object.keys(this.params)
            .map((key) => key + "=" + this.params[key])
            .join("&")
      )
      .pipe(
        map((response: MyResponseList) => {
          this._total$.next(response.totalRecord);
          this._systemConfigurations = response.data as SystemConfiguration[];
          this._systemConfigurations$.next(this._systemConfigurations);
          const filteredResult = baseFilter(response.data, tableState);
          const result: TableResponseModel<SystemConfiguration> = {
            items: filteredResult.items,
            total: filteredResult.total,
          };
          return result;
        })
      );
  }

  updateSystemConfigurations(updatedSystemConfiguration: SystemConfiguration) {
    const index = this._systemConfigurations.findIndex(
      (c) => c.id === updatedSystemConfiguration.id
    );
    if (index === -1) {
      this._systemConfigurations.push(updatedSystemConfiguration);
    } else {
      this._systemConfigurations[index] = updatedSystemConfiguration;
    }

    this._systemConfigurations$.next(this._systemConfigurations); // emit completely new value
  }
  getData(): Observable<MyResponseList> {
    return this.http.get<MyResponseList>(
      this.API_URL +
        "GetAll?" +
        Object.keys(this.params)
          .map((key) => key + "=" + this.params[key])
          .join("&")
    );
  }
  getIsActive(): Observable<MyResponseList> {
    return this.http.get<MyResponseList>(
      this.API_URL +
        "GetIsActive?" +
        Object.keys(this.params)
          .map((key) => key + "=" + this.params[key])
          .join("&")
    );
  }
  getsystemConfigurationById(id): Observable<MyResponseSingle> {
    return this.http.get<MyResponseSingle>(this.API_URL + "GetById?id=" + id);
  }
  createOrUpdatesystemConfiguration(
    systemConfiguration: SystemConfiguration
  ): Observable<MyResponseSingle> {
    return this.http.post<MyResponseSingle>(
      this.API_URL + "CreateOrUpdate",
      systemConfiguration
    );
  }
  getFromSysConfig(systemConfig ,key: string, type) {
    const value = systemConfig.find(con => con.key == key)?.value
    if (type == 1) {
      let img = environment.apiUrl + 'img/SystemConfiguration/natural/' + value;
      if (value)
        return img;
      else
        return this.defaultImg
    } else {
      return value ? value : ''
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
