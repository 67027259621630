// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '5.6',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  // TFS
  // apiUrl: "https://localhost:44385/",
  apiUrl: "https://api.luminsmith.com/",
  googleRecaptchaSiteKey: "6LfnqMUaAAAAABmrgCtmvDdNI-Xop1zk2PviQisZ",
  firebase: {
    apiKey: "AIzaSyDkVpPglD3sOlwew4T6chh8M2Zpf10EFwY",
    authDomain: "g-4bedf.firebaseapp.com",
    databaseURL: "https://g-4bedf.firebaseio.com",
    projectId: "g-4bedf",
    storageBucket: "g-4bedf.appspot.com",
    messagingSenderId: "199933238242",
    appId: "1:199933238242:web:513d2eb532edda722fb7bb",
    measurementId: "G-QWMM7CKGPD"
  }
};
try {
  if (!window.location.host.includes("localhost")) {
    let systemConfig = JSON.parse(localStorage.getItem("systemConfiguration"));
    environment.googleRecaptchaSiteKey = systemConfig.find(con => con.key == 'CaptchaKey').value
  }
} catch { }


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
