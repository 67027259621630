<!-- splash screen -->
<div #splashScreen id="splash-screen" *ngIf="splashScreenService.iSLoginSplash | async" >
  <div class="logo" *ngIf="systemConfig">
      <img [src]="systemConfigurationService.getFromSysConfig(systemConfig,'Black_Logo', 1)" alt="Logo" style="width:100%;margin: auto;margin-bottom: 30px"/>
  </div>
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg>
</div>
